<template>
    <div>
        <Button/>
    </div>
</template>

<script>

import Button from "./components/Button";

export default {
    name: 'App',
    components: {
        Button
    }
}
</script>

<style>
</style>
